import {css} from 'lit';

/* shared styles for all views */
export const sharedStyles = css`
    .material-icons {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 18px;
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
    }

    a {
        color: var(--black);
        font-weight: 500;
    }

    a:visited {
        color: var(--black);
    }

    .button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 16px 32px;
        background: black;
        border-radius: 24px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        min-width: 130px;
        height: 48px;
    }

    .button.secondary {
        background: none;
        color: black;

        &:hover {
            background: lightgrey;
        }
    }

    @keyframes rotate {
        from {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    .button-icon {
        animation: rotate 1.5s linear infinite;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-icon[hidden] {
        display: none;
    }

    label {
        font-family: 'Roboto', sans-serif;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 10px 0;
    }

    input {
        font-family: inherit;
        border: 1px solid #ccc;
        border-radius: 3px;
        background: var(--white);
        padding: 10px;
        color: var(--black);
    }

    input::placeholder {
        color: #ccc;
    }

    input:focus {
        outline: none;
        border-color: var(--black);
        -webkit-transition: border-color ease 300ms;
        transition: border-color ease 300ms;
    }

    input:disabled {
        opacity: 0.7;
    }

    .page-heading {
        margin: 0;
        font-size: 24px;
        font-family: 'montserrat';
        font-weight: 400;
    }

    .page-subtext {
        color: #5f5f5f;
        font-size: 16px;
        margin-top: 4px;
        font-family: 'Roboto', sans-serif;
        max-width: 80%;
    }

    .page-body {
        background: #fff;
        padding: 24px;
        border-radius: 24px;
        height: 80vh;
        max-width: 1200px;
    }
`;
