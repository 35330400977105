/**
 * Attempt to parse a error message out of the response
 * @param {Response} res The response object
 * @return {Promise<string|Response>} Returns a promise with the error message
 */
const getErrorMessage = async (res) => {
  try {
    const body = await res.json();

    if (body && body.error) {
      return body;
    } else if (body && Array.isArray(body) && body.length) {
      const hasError = body.some((item) => item.error);

      if (hasError) {
        return body;
      }
    }

    const text = await res.text();
    return text;
  } catch {
    return res;
  }
};

/**
 * `service`
 *  Handle the sending of requests, done in this way so all requests
 *  can use the same method and updates only required in one place
 *
 */
export default class Service {
  /**
   * Constructs class
   *
   * @param {Auth} auth the auth service
   * @param {string} apiURI the root uri of api
   */
  constructor(auth, apiURI) {
    this.auth = auth;
    this.root = apiURI;
  }

  /**
   * Creates a headers object including the accepting only json responses
   * Adding any authorization header
   *
   * @return {object} Returns the body
   */
  async getHeaders() {
    const header = new Headers();
    header.set('accept', 'application/json');
    try {
      const token = await this.auth.getTokenSilently();
      header.set('Authorization', `Bearer ${token}`);
    } catch (e) {
      // squash login error as just don't add the header
    }
    return header;
  }

  /**
   * Used to send the requests off, if 401 unauthorised then redirect to
   * the login page
   *
   * @param {string} url The end point you wish to make the request to
   * @param {string} method The request method, defaults to get e.g. GET, POST
   * @param {object} body Any body you want to send off
   * @return {Promise<object>} Returns a promise based on the request
   */
  async send(url, method, body) {
    const headers = await this.getHeaders();
    const requestOptions = {
      url,
      method,
      mode: 'cors',
      cache: 'default',
      headers,
    };

    if (body) {
      requestOptions.body = body;
    }

    const request = new Request(
        `${this.root}${requestOptions.url}`,
        requestOptions,
    );
    try {
      const res = await fetch(request);
      if (res.ok && res.status === 204) {
        return;
      }
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        await this.auth.loginWithRedirect({
          redirect_uri: window.location.origin,
        });
        return;
      }
      const error = await getErrorMessage(res);
      return Promise.reject(error);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
