import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import {status} from './statuses';
import {datadogRum} from '@datadog/browser-rum';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-dialog';
/**
 * `schedule-cancel`
 *  Allow a user to cancel a schedule
 */
export class ScheduleCancel extends LitElement {
  /**
     * Defines the elements styles
     *
     * @return {CSSResult} the resulting styles
     */
  static get styles() {
    const style = css`
        mwc-button.cancel {
          margin-left: 25px;
        }`;
    return [sharedStyles, style];
  }
  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    return html`
    <mwc-button class="cancel" @click=${this.toggleDialog}>Cancel</mwc-button>
      <mwc-dialog heading="Cancel Schedule">
            <p>Please confirm you wish to cancel the schedule, by entering "confirm" below.</p>
            <mwc-textfield class="confirmation" label="confirm" required pattern="confirm">
            </mwc-textfield>
            <p class="error" ?hidden=${this.state !== status.Errored}>
                Oops there has been an error cancelling the schedule. ${this.error?.error || this.error}
            </p>
            <p class="saving" ?hidden=${this.state !== status.Saving}>Cancelling schedule.</p>
            <mwc-button class="submit" raised slot="primaryAction" @click=${this.cancelSchedule}>
                Confirm
            </mwc-button>
            <mwc-button class="cancel" slot="secondaryAction" dialogAction="close">
                Close
            </mwc-button>
        </mwc-dialog>
      `;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The service for getting data */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** Any error from the API */
      error: {type: Object},
      /** The schedule returned from the API */
      schedule: {type: Object},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.state = status.Idle;
    this.error = {};
    this.schedule = {};
  }

  /**
     * Helper to get the dialog box
     * @return {HTMLElement} the dialog
     */
  get dialog() {
    return this.shadowRoot.querySelector('mwc-dialog');
  }

  /**
     * Shows the delete dialog
     */
  toggleDialog() {
    this.dialog.show();
  }

  /**
   * Cancels the schedule so discounts aren't triggered
   *
   */
  async cancelSchedule() {
    const input = this.shadowRoot.querySelector('mwc-textfield');
    const isValid = input.reportValidity();
    if (!isValid) {
      return;
    }

    const name = this.schedule.name;
    try {
      this.state = status.Saving;
      await this.service.send(`/schedules/${name}`, 'DELETE');
      this.state = status.Saved;
      this.dispatchEvent(new CustomEvent('schedule-cancelled', {
        bubbles: true,
        composed: true,
      }));
      this.dialog.close();
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'cancelling-schedule', name});
    }
  }
}
window.customElements.define('schedule-cancel', ScheduleCancel);
