import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import {status} from './statuses';
import {datadogRum} from '@datadog/browser-rum';
import '@material/mwc-button';

/**
 * `upload-item`
 *  List item for an upload, used for downloading its data
 */
export class UploadItem extends LitElement {
  /**
     * Defines the elements styles
     *
     * @return {CSSResult} the resulting styles
     */
  static get styles() {
    const style = css`
        :host {
            display: block;
            border-radius: 5px;
            margin: 20px;
            padding: 5px 10px 20px;
            background: whitesmoke;
            color: var(--black);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }`;
    return [sharedStyles, style];
  }

  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    return html`
      <div>
        <p class="uploadedBy"><b>Uploaded By:</b> ${this.upload?.uploader}</p>
        <p class="uploadDate"><b>Uploaded At:</b> ${UploadItem.localiseTime(this.upload?.uploadDate)}</p>
        <p class="fileName"><b>Original File Name:</b> ${this.upload?.originalFileName}</p>
      </div>
      <div class="actions">
          <mwc-button class="download" raised @click=${this.downloadFile}>Download File</mwc-button>
      </div>
      <p class="error" ?hidden=${this.state !== status.Errored}>
        There has been an error downloading the file. ${this.error?.error || this.error}
      </p>
      <p class="loading" ?hidden=${this.state !== status.Loading}>Downloading file.</p>
      `;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The service for getting data */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** Any error from the API */
      error: {type: Object},
      /** The upload returned from the API */
      upload: {type: Object},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.state = status.Idle;
    this.error = {};
    this.upload = {};
  }

  /**
     *  Convert the time to local date format
     *
     * @param {string} time the time
     * @return {string} the formatted date time
     */
  static localiseTime(time) {
    if (!time) {
      return '';
    }

    // passing undefined locale allows the browser to detect it automatically
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_options
    return new Intl.DateTimeFormat(undefined, {
      dateStyle: 'full',
      timeStyle: 'long',
    }).format(new Date(time));
  }

  /**
   * Download the price-related upload
   */
  async downloadFile() {
    const objectKey = this.upload.objectKey;
    try {
      this.state = status.Loading;
      const {url} = await this.service.send(`/uploads/${objectKey}`, 'GET');
      const link = document.createElement('a');
      link.href = url;
      link.click();
      link.remove();

      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'downloading-price-file', objectKey});
    }
  }
}
window.customElements.define('upload-item', UploadItem);
