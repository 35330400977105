import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
import {status} from './statuses';
import {datadogRum} from '@datadog/browser-rum';
import '@material/mwc-button';
import './schedule-cancel';
/**
 * `schedule-item`
 *  List item for a schedule, used for cancelling or downloading its data
 */
export class ScheduleItem extends LitElement {
  /**
     * Defines the elements styles
     *
     * @return {CSSResult} the resulting styles
     */
  static get styles() {
    const style = css`
        :host {
            display: block;
            border-radius: 5px;
            margin: 20px;
            padding: 5px 10px 20px;
            background: whitesmoke;
            color: var(--black);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }`;
    return [sharedStyles, style];
  }

  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    return html`
      <h3>Scheduled For: ${ScheduleItem.localiseTime(this.schedule?.scheduledTime)}</h3>
      <div>
        <p class="createdAt"><b>Created At:</b> ${ScheduleItem.localiseTime(this.schedule?.createdAt)}</p>
        <p class="createdBy"><b>Created By:</b> ${this.schedule?.createdBy}</p>
        <p class="file"><b>Filename:</b> ${this.schedule?.originalFileName}</p>
        <p class="rows"><b>Product Variants Affected:</b> ${this.schedule?.rows}</p>
        <small>Schedules can only be cancelled if they are more than 10 minutes in the future</small>
      </div>
      <div class="actions">
          <mwc-button class="download" raised @click=${this.downloadFile}>Download File</mwc-button>
          <schedule-cancel .service=${this.service} .schedule=${this.schedule}></schedule-cancel>
      </div>
      <p class="error" ?hidden=${this.state !== status.Errored}>
        Oops there has been an error downloading the file. ${this.error?.error || this.error}
      </p>
      <p class="loading" ?hidden=${this.state !== status.Loading}>Downloading file.</p>
      `;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The service for getting data */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** Any error from the API */
      error: {type: Object},
      /** The schedule returned from the API */
      schedule: {type: Object},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.service = {};
    this.state = status.Idle;
    this.error = {};
    this.schedule = {};
  }

  /**
     *  Convert the time to local date format
     *
     * @param {string} time the time
     * @return {string} the formatted date time
     */
  static localiseTime(time) {
    if (!time) {
      return '';
    }

    // passing undefined locale allows the browser to detect it automatically
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_options
    return new Intl.DateTimeFormat(undefined, {
      dateStyle: 'full',
      timeStyle: 'long',
    }).format(new Date(time));
  }

  /**
   * Download the file that is scheduled at that time
   */
  async downloadFile() {
    const name = this.schedule.name;
    try {
      this.state = status.Loading;
      const {url} = await this.service.send(`/schedules/${name}/file`, 'GET');
      const link = document.createElement('a');
      link.href = url;
      link.download = `${name}.csv`;
      link.click();
      link.remove();

      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'downloading-schedule-file', name});
    }
  }
}
window.customElements.define('schedule-item', ScheduleItem);
