import {LitElement, html} from 'lit';
import '@material/mwc-button';
import {status} from '../elements/statuses';
import {sharedStyles} from '../shared-styles';
import {datadogRum} from '@datadog/browser-rum';
import '../elements/schedule-item';

/**
 * `schedule-management-page`
 *  List the schedules and allow the user to cancel them and see what has been scheduled
 */
export class ScheduleManagementPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    return [sharedStyles];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    const header = html`
      <h2 class="page-heading">Scheduled Discounts</h2>
      <p>
        It can take up a minute after creating a schedule for it to appear here.
      </p>
    `;

    if (this.state === status.Errored) {
      const content = html`<p class="error">
        Oops there has been an error. <a href="/">Go home</a>
      </p>`;
      return [header, content];
    }

    if (this.state === status.Loading) {
      const content = html`<div class="page-body">
        <p class="loading">Loading schedules...</p>
      </div>`;
      return [header, content];
    }

    if (this.schedules.length === 0 && this.state === status.Loaded) {
      const content = html`<div class="page-body">
        <p class="loading">No schedules configured.</p>
      </div>`;
      return [header, content];
    }

    const content = html` <div class="page-body">
      <div class="schedules">
        ${this.schedules.map(
      (schedule) =>
        html`<schedule-item
              .service=${this.service}
              .schedule=${schedule}
            ></schedule-item>`,
  )}
      </div>
    </div>`;

    return [header, content];
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** The list of schedules planned */
      schedules: {type: Array},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {};
    this.service = {};
    this.state = status.Loading;
    this.schedules = [];
  }

  /** Add any event listeners */
  connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
    this.addEventListener('schedule-cancelled', this.getSchedules);
  }

  /** Remove any event listeners */
  disconnectedCallback() {
    if (super.disconnectedCallback) {
      super.disconnectedCallback();
    }
    this.removeEventListener('schedule-cancelled', this.getSchedules);
  }

  /**
   * Fired whenever any property changes and the template updates
   *
   * @param {PropertyValues} changedProperties map of changed properties
   */
  updated(changedProperties) {
    if (super.updated) {
      super.updated();
    }

    if (
      (changedProperties.has('routeData') ||
        changedProperties.has('service')) &&
      this.routeData?.params?.page === 'schedule-management' &&
      this.service?.send
    ) {
      this.getSchedules();
    }
  }

  /**
   * Call to load the scheduleds
   *
   */
  async getSchedules() {
    this.state = status.Loading;
    try {
      const schedules = await this.service.send('/schedules', 'GET');
      schedules.sort(
          (a, b) => new Date(a.scheduledTime) - new Date(b.scheduledTime),
      );
      this.schedules = schedules;
      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'schedule-management-page'});
    }
  }
}
window.customElements.define(
    'schedule-management-page',
    ScheduleManagementPage,
);
