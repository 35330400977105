import {LitElement, html, css} from 'lit';
import {datadogRum} from '@datadog/browser-rum';
import '@material/mwc-button';
import {status} from '../elements/statuses';
import {sharedStyles} from '../shared-styles';
import '../elements/upload-item';

/**
 * `intake-rrp-conversion-page`
 *  Display the uploader for intake rrps conversion files
 */
export class IntakeRRPConversionPage extends LitElement {
  /**
     * Defines the elements styles
     *
     * @return {CSSResult} the resulting styles
     */
  static get styles() {
    const style = css`
            small {
                display: block;
                margin: 10px;
            }
        `;
    return [sharedStyles, style];
  }

  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    return html`
            <h2 class="page-heading">Intake RRP Conversion Uploader</h2>
            <div class="page-body">
                <form>
                    <label>
                        Upload the Intake RRP Conversion CSV file
                        <input
                            name="upload"
                            type="file"
                            accept=".csv"
                            required
                        />
                    </label>
                    <small
                        >The CSV file is expected in a specific format,
                        <a
                            href="/templates/intake-rrp-conversion.csv"
                            download="intake-rrp-conversion.csv"
                            >download the template here</a
                        >
                    </small>
                    <mwc-button
                        class="submit"
                        raised
                        @click=${this.handleUpload}
                    >
                        Upload
                    </mwc-button>
                    <mwc-button class="cancel" @click=${this.resetForm}>
                        Clear
                    </mwc-button>
                    <p class="error" ?hidden=${this.state !== status.Errored}>
                        Oops there has been an error uploading.
                        ${this.error?.error || this.error}
                    </p>
                    <p class="saving" ?hidden=${this.state !== status.Saving}>Uploading file.</p>
                </form>
                <h2>Intake RRP Conversion Uploads History</h2>
                <mwc-button
                    class="view"
                    raised
                    @click=${this.getConversionUploads}
                >
                    View Uploads
                </mwc-button>
                <div class="uploads">
                    ${this.conversionUploads.map(
      (upload) =>
        html`<upload-item
                                .service=${this.service}
                                .upload=${upload}
                            ></upload-item>`,
  )}
                </div>
                <p class="error" ?hidden=${this.state !== status.Errored}>
                    There has been an error getting the uploads.
                    ${this.error?.error || this.error}
                </p>
                <p class="loading" ?hidden=${this.state !== status.Loading}>
                    Loading uploads.
                </p>
            </div>
        `;
  }

  /**
     * Defines the elements properties
     *
     * @return {object} the props
     */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** Any error from the API */
      error: {type: Object},
      /** The list of conversion upload files */
      conversionUploads: {type: Array},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {};
    this.service = {};
    this.state = status.Idle;
    this.error = {};
    this.conversionUploads = [];
  }

  /**
     * Helper to get the form element
     * @return {HTMLElement} the form element
     */
  get form() {
    return this.shadowRoot.querySelector('form');
  }

  /**
     * Wrapper to reset the form and state
     */
  resetForm() {
    this.form.reset();
    this.state = status.Idle;
  }

  /**
     * Retrieves conversion mapping uploads
     */
  async getConversionUploads() {
    try {
      this.state = status.Loading;
      let uploads = await this.service.send(
          '/uploads/conversions',
          'GET',
      );
      uploads = uploads.sort((a, b) => {
        return new Date(b.uploadDate) - new Date(a.uploadDate);
      });
      this.conversionUploads = uploads;
      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'intake-rrp-conversion-page'});
    }
  }

  /**
     * Handles the submission of the form, getting the data from the form and sending it to the service.
     * Capture the error in datadog and display a friendly error message if possible
     *
     * @param {Event} event the submission event
     */
  async handleUpload(event) {
    event.preventDefault();
    try {
      this.state = status.Saving;
      const valid = this.form.reportValidity();
      if (!valid) {
        return;
      }
      const formdata = new FormData(this.form);
      await this.service.send('/uploads/conversion', 'POST', formdata);
      this.state = status.Idle;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'intake-rrp-conversion-page'});
    }
  }
}
window.customElements.define(
    'intake-rrp-conversion-page',
    IntakeRRPConversionPage,
);
