import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
/**
 * `home-page`
 *  The first page the user sees
 */
export class HomePage extends LitElement {
  /**
     * Defines the elements styles
     *
     * @return {CSSResult} the resulting styles
     */
  static get styles() {
    const style = css``;
    return [sharedStyles, style];
  }

  /**
     * Defined the elements content
     *
     * @return {TemplateResult} the resulting html template
     */
  render() {
    return html``;
  }
}
window.customElements.define('home-page', HomePage);
